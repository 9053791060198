<template>
  <div>
    <div class="sticky-header">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h3>
          {{ $t('document.documentsOverview.tenantDocumentsTable.title') }}
        </h3>
        <DownloadFiles :documents="documents" :limit="limit" :is-permitted="isBulkDownloadPermitted" />
      </div>
      <div class="d-flex">
        <span class="filter-item">
          <DatePickerTableFilter
            :filter-name="$t('document.documentsOverview.tenantDocumentsTable.header.recordedAt')"
            :filter-value="dateRangeRecordedText"
            value-format="yyyy-MM-dd"
            type="daterange"
            size="small"
            :align="$direction === 'rtl' ? 'right' : 'left'"
            :date-range="dateRangeRecorded"
            :clearable="true"
            @on-date-changed="onChangedDateRangeRecorded"
            @on-clear-filter="onChangedDateRangeRecorded(null)"
          />
        </span>
        <span class="filter-item">
          <SearchableDropdown
            :selected-item="selectedSupplierItem"
            :options="suppliers"
            :label="$t('document.documentsOverview.tenantDocumentsTable.header.supplierName')"
            :search-placeholder="$t('commons.searchWithDots')"
            @change="onChangedSupplier"
          />
        </span>
        <span class="filter-item">
          <CheckableDropdown
            :options="documentsTypeWithNames"
            :label="$t('document.documentsOverview.tenantDocumentsTable.header.documentType')"
            @change="onChangedDocumentType"
          />
        </span>
        <span class="filter-item">
          <DatePickerTableFilter
            :filter-name="$t('document.documentsOverview.tenantDocumentsTable.header.issueDate')"
            :filter-value="issueDateRangeText"
            value-format="yyyy-MM-dd"
            type="daterange"
            size="small"
            :align="$direction === 'rtl' ? 'right' : 'left'"
            :date-range="issueDateRange"
            :clearable="true"
            @on-date-changed="onChangedIssueDateFilter"
            @on-clear-filter="onChangedIssueDateFilter(null)"
          />
        </span>
      </div>
    </div>
    <TableLoadingSkeleton v-if="loading" />
    <div v-else>
      <Table
        :columns="columns"
        :data="mutatedData"
        :active-sort="activeSort"
        show-index
        rounded
        border
        @row-click="handleRowClick"
      >
        <template #cell-index="{ rowIndex }">
          {{ rowIndex + 1 + limit * (page - 1) }}
        </template>

        <template #cell-issueDate="{ rowData: { issueDate } }">
          {{ formatDateUTC(issueDate) }}
        </template>
        <template #cell-documentType="{ rowData: { documentType } }">
          {{ $t(`document.exports.schema.type.shortName.${documentType}`) }}
        </template>
        <template #cell-recordedAt="{ rowData: { recordedAt } }">
          {{ formatDate(recordedAt) }}
        </template>

        <template #cell-totalAmount="{ rowData: { totalAmount } }">
          {{ formatMoney(totalAmount) }}
        </template>

        <template #cell-documentNumber="{ rowData: { documentNumber, replicateOf } }">
          {{ documentNumber }}
          <Tag v-if="replicateOf" :type="'neutral'" :class="$direction === 'rtl' ? 'ms-2' : 'me-2'">{{
            $t('document.validation.documentNumber.replicatedDocument')
          }}</Tag>
        </template>
      </Table>
      <div class="d-flex justify-content-end my-4">
        <el-pagination
          v-if="mutatedData.length"
          small
          layout="prev, pager, next, jumper"
          background
          :current-page.sync="page"
          :page-size="limit"
          :page-count="Math.ceil(documentsCount / limit)"
        />
      </div>
    </div>

    <DocumentModal v-if="previewDocumentId" visible :document-id="previewDocumentId" @close="previewIndex = null">
      <template #header>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :page-size="1"
          :total="mutatedData.length"
          :current-page="previewIndex + 1"
          :pager-count="5"
          @update:currentPage="previewIndex = $event - 1"
        />
      </template>
    </DocumentModal>
  </div>
</template>

<script>
import { computed, ref, watch, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { SearchableDropdown, CheckableDropdown, DatePickerTableFilter } from './table-filters';

import { Table, TableLoadingSkeleton } from '@/modules/core';
import { useSuppliersNew } from '@/modules/suppliers';
import { options } from '@/locale/dateConfig';
import { DocumentModal } from '@/modules/documentModal';
import { DOCUMENT_TYPES } from '@/modules/document/types';
import { useDocuments, useCurrency } from '@/modules/document/compositions';
import DownloadFiles from '../components/DownloadFiles';
import { useTenancy } from '@/modules/auth';
import Tag from '@/modules/core/components/Tag.vue';
import { useUserPermissions } from '@/modules/auth/compositions';
import { USER_PERMISSIONS_LIST } from '@/permissions';

const TABLE_HEADERS = {
  ISSUE_DATE: 'issueDate',
  DOCUMENT_TYPE: 'documentType',
  SUPPLIER_NAME: 'supplierName',
  DOCUMENT_NUMBER: 'documentNumber',
  TOTAL_AMOUNT: 'totalAmount',
  RECORDED_AT: 'recordedAt',
};

export default {
  components: {
    Table,
    DocumentModal,
    DownloadFiles,
    TableLoadingSkeleton,
    DatePickerTableFilter,
    SearchableDropdown,
    CheckableDropdown,
    Tag,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenantId } = useTenancy();
    const limit = ref(50);
    const page = ref(1);
    const sort = ref({
      sortBy: 'recordedAt',
      orderBy: 'desc',
    });

    const issueDateRange = ref([]);
    const issueDateRangeText = ref('');
    const dateRangeRecordedText = ref('');
    const { currencyLocaleStyle } = useCurrency();

    const dateRangeRecorded = ref();
    const documentTypesFilter = ref([]);
    const supplierIdFilter = computed({
      get: () => root.$route.query.supplierId,
      set: (value) =>
        root.$router.replace({ ...root.$route, query: { ...root.$route.query, supplierId: value || undefined } }),
    });
    watch([issueDateRange, dateRangeRecorded, documentTypesFilter, supplierIdFilter], () => (page.value = 1), {
      immediate: true,
    });

    const documentFilterVariables = computed(() => ({
      businessId: currentTenantId.value,
      pagination: {
        limit,
        offset: limit.value * (page.value - 1),
      },
      ...(sort.value
        ? {
            sort: {
              sortBy: sort.value.sortBy,
              orderBy: sort.value.orderBy,
            },
          }
        : null),
      fromDate: issueDateRange.value?.[0],
      toDate: issueDateRange.value?.[1],
      recordedAtFrom: dateRangeRecorded.value?.[0],
      recordedAtTo: dateRangeRecorded.value?.[1],
      supplierId: supplierIdFilter.value,
      types: documentTypesFilter.value,
      recorded: true,
    }));
    const { documents, totalCount: documentsCount, loading: documentsLoading } = useDocuments(documentFilterVariables);
    const { suppliers, loading: suppliersLoading } = useSuppliersNew();

    const selectedSupplierItem = computed(
      () => suppliers.value.find((item) => item.id === supplierIdFilter.value) ?? null
    );
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const isBulkDownloadPermitted = isUserPermittedForActiveTenant(USER_PERMISSIONS_LIST['document.bulkDownload']);
    const isViewTypeOtherDocumentsPermitted = isUserPermittedForActiveTenant(
      USER_PERMISSIONS_LIST['document.typeOther']
    );

    const documentsTypeWithNames = computed(() =>
      Object.values(DOCUMENT_TYPES).map((type) => ({
        id: type,
        name: `${root.$i18n.t(`document.exports.schema.type.shortName.${type}`)}`,
        isPermitted: DOCUMENT_TYPES.OTHER === type && !isViewTypeOtherDocumentsPermitted.value ? false : true,
      }))
    );

    return {
      issueDateRangeText,
      issueDateRange,
      currencyLocaleStyle,
      dateRangeRecordedText,
      dateRangeRecorded,
      page,
      limit,
      sort,
      documentsCount,
      documents,
      suppliers: computed(() => [...suppliers.value].sort((a, b) => a.name.localeCompare(b.name))),
      selectedSupplierItem,
      loading: computed(() => documentsLoading.value || suppliersLoading.value),
      activeSort: ref({ direction: -1, columnKey: TABLE_HEADERS.RECORDED_AT }),
      supplierIdFilter,
      documentTypesFilter,
      documentsType: Object.values(DOCUMENT_TYPES),
      documentsTypeWithNames,
      previewIndex: ref(null),
      isBulkDownloadPermitted,
    };
  },
  computed: {
    previewDocumentId() {
      return this.mutatedData[this.previewIndex]?.id;
    },
    columns() {
      return [
        {
          header: this.$t('document.documentsOverview.tenantDocumentsTable.header.recordedAt'),
          key: TABLE_HEADERS.RECORDED_AT,
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.RECORDED_AT }),
        },
        {
          header: this.$t('document.documentsOverview.tenantDocumentsTable.header.supplierName'),
          key: TABLE_HEADERS.SUPPLIER_NAME,
        },
        {
          header: this.$t('document.documentsOverview.tenantDocumentsTable.header.documentType'),
          key: TABLE_HEADERS.DOCUMENT_TYPE,
        },
        {
          header: this.$t('document.documentsOverview.tenantDocumentsTable.header.documentNumber'),
          key: TABLE_HEADERS.DOCUMENT_NUMBER,
        },
        {
          header: this.$t('document.documentsOverview.tenantDocumentsTable.header.issueDate'),
          key: TABLE_HEADERS.ISSUE_DATE,
          sortCallback: (direction) => (this.activeSort = { direction, columnKey: TABLE_HEADERS.ISSUE_DATE }),
        },
        {
          header: this.$t('document.documentsOverview.tenantDocumentsTable.header.totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
        },
      ];
    },
    mutatedData() {
      return this.documents.map(
        ({ issueDate, type, documentNumber, totalAmount, supplier, id, recordedAt, replicateOf }) => {
          return {
            id,
            issueDate,
            documentType: type,
            documentNumber,
            totalAmount,
            supplierName: supplier?.name ?? '-',
            recordedAt,
            replicateOf,
          };
        }
      );
    },
  },
  watch: {
    activeSort({ columnKey, direction }) {
      const sortableColumns = [TABLE_HEADERS.ISSUE_DATE, TABLE_HEADERS.RECORDED_AT];

      if (sortableColumns.includes(columnKey)) {
        this.sort = {
          sortBy: columnKey,
          orderBy: direction < 0 ? 'desc' : 'asc',
        };
      } else {
        this.sort = null;
      }
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
    formatDateUTC(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' }) : null;
    },
    formatMoney(value) {
      return typeof value === 'number' && !Number.isNaN(value)
        ? Number(value).toLocaleString(this.$i18n.locale, { ...this.currencyLocaleStyle, maximumFractionDigits: 5 })
        : '-';
    },
    handleRowClick(rowIndex) {
      this.previewIndex = rowIndex;
    },

    onChangedDateRangeRecorded(date) {
      this.dateRangeRecorded = date ?? null;

      this.dateRangeRecordedText = this.getDateRangeText(date, 'issueDateRange');
    },
    onChangedDocumentType(selected) {
      const ids = selected.map((item) => item.id);
      this.documentTypesFilter = ids;
    },
    onChangedSupplier(selected) {
      this.supplierIdFilter = selected?.id;
    },
    onChangedIssueDateFilter(date) {
      this.issueDateRange = date ?? null;
      this.issueDateRangeText = this.getDateRangeText(date, 'issueDateRange');
    },
    getDateRangeText(date) {
      if (!date) return '';

      const inputFormat = 'yyyy-MM-dd';

      const from = DateTime.fromFormat(date[0], inputFormat).toLocaleString(DateTime.DATE_SHORT, this.$i18n.locale);
      const to = DateTime.fromFormat(date[1], inputFormat).toLocaleString(DateTime.DATE_SHORT, this.$i18n.locale);

      return this.$direction === 'rtl' ? `${to} - ${from}` : `${from} - ${to}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.filter-item {
  margin-left: 0.5rem;
}

.sticky-header {
  background-color: $white;
  z-index: 1200;
  position: sticky;
  top: 12.4rem;
}
</style>
