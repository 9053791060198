<template>
  <el-dialog visible top="33vh" custom-class="rounded" :show-close="false" width="430px">
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-0">
        <h2>{{ $t('document.CommentReviewModal.header.title') }}</h2>
        <Button type="icon" class="p-0" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>

    <p class="modal-text-body pb-1">{{ $t('document.CommentReviewModal.body.textArea') }}</p>
    <el-input v-model="model.comments" type="textarea" class="pb-1" />

    <p v-if="showError" class="error-message">{{ $t('document.CommentReviewModal.error.invalidComment') }}</p>

    <div slot="footer">
      <Button size="medium" type="secondary" @click="$emit('close')">
        {{ $t('document.CommentReviewModal.footer.close') }}
      </Button>
      <Button size="medium" @click="handleSubmittedComment">
        {{ $t('document.CommentReviewModal.footer.save') }}
      </Button>
    </div>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

export default {
  components: { Button, CloseIcon },
  props: {
    comment: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      showError: false,
      model: {
        comments: this.comment || '',
      },
    };
  },
  methods: {
    handleSubmittedComment() {
      const isValidComment = this.validateComment();
      if (!isValidComment) return (this.showError = true);

      this.$emit('commentSubmitted', this.model.comments);
      this.$emit('close');
    },
    validateComment() {
      if (this.model.comments.length < 1) return false;
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.bold-radio {
  font-weight: bold;
}

.file-preview {
  width: 100%;
  border: 1px solid #d9dcde;
  border-radius: 4px;
  background-color: #fbfbfb;
  color: #131416;
}
::v-deep .el-dialog__body {
  padding: 20px 20px;
}
.modal-text-body {
  font-size: 15px;
  font-weight: 500;
  color: #131416;
}

.error-message {
  color: #e52044;
}

::v-deep textarea {
  resize: none;
  height: 67px;
}
</style>
