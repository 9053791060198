<template>
  <div>
    <el-tooltip
      v-for="(value, index) in values"
      :key="index"
      class="box-item"
      effect="dark"
      :disabled="!value.highlighted"
      :content="$t('document.structureParamsTags.uniqueParam')"
      placement="top"
    >
      <Tag
        :key="index"
        :type="value.highlighted ? 'info' : 'neutral'"
        class="mb-2"
        :class="$direction === 'rtl' ? 'ms-2' : 'me-2'"
        >{{ value.name }}</Tag
      >
    </el-tooltip>
  </div>
</template>

<script>
import Tag from '@/modules/core/components/Tag.vue';

export default {
  components: {
    Tag,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
